import { useEffect, useState } from 'react';

import loadApp, { App } from './app';
import loadAppLauncher from './app-launcher';
import loadBarcodeScanner from './barcode-scanner';
import loadBarcodeScannerOld from './barcode-scanner-old';
import loadBrowser from './browser';
import loadCamera from './camera';
import loadCore from './core';
import loadDevice from './device';
import loadKeyboard from './keyboard';
import loadNetwork from './network';
import loadPreferences from './preferences';
import loadSplashScreen from './splash-screen';

enum CapacitorVersion {
  v6_2 = 'capacitor-v6_2',
  v6 = 'capacitor-v6',
  v5 = 'capacitor-v5',
  v4 = 'capacitor-v4',
}

// When a capacitor version is deprecated add over here
type DeprecatedCapacitorVersion = CapacitorVersion.v4 | CapacitorVersion.v5;
type SupportedCapacitorVersion = Exclude<CapacitorVersion, DeprecatedCapacitorVersion>;

// Add new versions at the top of the object and remove the deprecated versions from the list
const versionLoaders = {
  [CapacitorVersion.v6_2]: async () =>
    (await import(/* webpackChunkName: "capacitor-v6" */ './v6')).default,
  [CapacitorVersion.v6]: async () =>
    (await import(/* webpackChunkName: "capacitor-v6" */ './v6')).default,
};

const supportedVersions = Object.entries(versionLoaders).map(([version]) => version);

const getVersion = () => {
  // On web, window.Capacitor is undefined until capacitor is "imported" - however, on native app,
  // window.Capacitor is always present.
  // We can use this trick to detect web platform, and return the latest version.
  // We should however replace this check after we deprecate Capacitor 4
  // Because with Capacitor 5+ now we get `capacitor-v*` in userAgent for all native apps, however web wouldn't have that value which would indicate its a web platform.
  if (!window.Capacitor) {
    return supportedVersions[0];
  }

  const nativeCapacitorVersion = Object.values(CapacitorVersion).find(version =>
    navigator.userAgent.includes(version)
  );

  // From Capacitor 5 onwards, in `capacitor.config.json` we append userAgent value to indicate which particular version of capacitor they are built with. Any apps built before that will
  // be treated as Capacitor 4 apps.
  return nativeCapacitorVersion ?? CapacitorVersion.v4;
};

/**
 * This hook should only be called once as its required, calling it more than once can lead to performance
 * issues as it will try to load the capacitor plugins all again, to avoid the situation we throw an error
 * as soon as the hook is being called again.
 */
const useRegisterCapacitorPlugins = () => {
  const [isRegistering, setIsRegistering] = useState(true);
  const [isObsoleteVersion, setIsObsoleteVersion] = useState(false);

  useEffect(() => {
    if (App) {
      throw Error('useRegisterCapacitorPlugins should only be called once.');
    }

    const effect = async (version: SupportedCapacitorVersion) => {
      const plugins = await versionLoaders[version]();

      loadApp(plugins.App);
      loadAppLauncher(plugins.AppLauncher);
      loadBarcodeScanner(plugins.BarcodeScanner);
      loadBrowser(plugins.Browser);
      loadCamera(plugins.Camera);
      loadCore(plugins.Core);
      loadDevice(plugins.Device);
      loadKeyboard(plugins.Keyboard);
      loadNetwork(plugins.Network);
      loadPreferences(plugins.Preferences);
      loadSplashScreen(plugins.SplashScreen);
      loadBarcodeScannerOld(plugins.BarcodeScannerOld);

      setIsRegistering(false);
    };

    const version = getVersion();
    if (supportedVersions.includes(version)) {
      // skipcq: JS-0328
      effect(version as SupportedCapacitorVersion);
    } else {
      // Users on cap v4 apps will be redirected to /legacy route
      // wherein they will see an option to download the new app(Check In & Lead Capture)
      // For all other cap versions, we handle the obsolete flag and if true we show the app update required screen
      setIsObsoleteVersion(version !== CapacitorVersion.v4);
      // If we're forcing an incompatible version, we should load the oldest supported version.
      // skipcq: JS-0328
      effect(supportedVersions[supportedVersions.length - 1] as SupportedCapacitorVersion);
    }
  }, []);

  return {
    isRegistering,
    isObsoleteVersion,
  };
};

export default useRegisterCapacitorPlugins;
